<template>
  <div
    class="card"
    :style="{
      backgroundImage:
        'url(' + iceBackground + '), url(' + game.homeTeam.background + ')'
    }"
  >
    <h3>
      <span class="line">{{ game.homeTeam.name }}</span> vs
      <span class="line">{{ game.awayTeam.name }}</span>
    </h3>
    <div class="logo-wrapper">
      <div class="logo-container">
        <span class="helper"></span>
        <img :src="game.homeTeam.logo" class="team-logo" />
      </div>
      <div class="logo-container vs-text">VS</div>
      <div class="logo-container">
        <span class="helper"></span>
        <img :src="game.awayTeam.logo" class="team-logo" />
      </div>
    </div>
    <p class="arena">@ {{ game.homeTeam.arena }}</p>
    <button @click="watchRecap" class="btn-grad">WATCH RECAP</button>
  </div>
</template>

<script>
const iceBackground = require('../assets/ice-background.png');
export default {
  name: 'GameItem',
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    iceBackground
  }),
  methods: {
    watchRecap() {
      window.location.href = this.game.url;
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  margin: 1.5rem 3rem 3rem 3rem;
  padding: 1rem 3rem;
  width: 30rem;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-size: cover;
  background-position: center;

  @media (max-width: 48rem) {
    margin: 1.5rem 1rem;
    padding: 1rem;
    width: 20rem;

    h3 {
      font-size: 1.17rem;
      margin: 0;
    }
  }

  .logo-wrapper {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    height: 10rem;
    flex-wrap: nowrap;

    @media (max-width: 48rem) {
      height: 6rem;
    }

    .logo-container {
      max-height: 10rem;
      position: relative;
      width: 100%;

      &.vs-text {
        font-size: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: italic;
        margin: 2rem;
      }

      .helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }

      .team-logo {
        margin: auto;
        max-width: 10rem;
        max-height: 10rem;
        vertical-align: middle;
        filter: drop-shadow(5px 5px 5px #444);

        @media (max-width: 48rem) {
          max-width: 5rem;
          max-height: 5rem;
        }
      }
    }
  }

  span.line {
    display: inline-block;
  }

  .arena {
    font-style: italic;
  }

  button.watch-btn {
    width: 100%;
    padding: 1rem;
    background-color: lightslategray;
    outline: none;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    font-weight: bold;
  }

  .btn-grad {
    background-image: linear-gradient(
      to right,
      #314755 0%,
      #26a0da 51%,
      #314755 100%
    );
  }
  .btn-grad {
    padding: 1rem 3rem;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    width: 100%;
    outline: none;
    border: none;
  }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
}
</style>
