export default [
  {
    name: 'Anaheim Ducks',
    arena: 'Honda Center',
    logo: require('./logos/ana.png'),
    code: 'ANA',
    background: require('./ana-home.jpg')
  },
  {
    name: 'Arizona Coyotes',
    arena: 'Gila River Arena',
    logo: require('./logos/ari.png'),
    code: 'ARI',
    background: require('./ari-home.jpg')
  },
  {
    name: 'Boston Bruins',
    arena: 'TD Garden',
    logo: require('./logos/bos.png'),
    code: 'BOS',
    background: require('./bos-home.jpg')
  },
  {
    name: 'Buffalo Sabres',
    arena: 'KeyBank Center',
    logo: require('./logos/buf.png'),
    code: 'BUF',
    background: require('./buf-home.jpg')
  },
  {
    name: 'Calgary Flames',
    arena: 'Scotiabank Saddledome',
    logo: require('./logos/cgy.png'),
    code: 'CGY',
    background: require('./cgy-home.jpg')
  },
  {
    name: 'Carolina Hurricanes',
    arena: 'PNC Arena',
    logo: require('./logos/car.png'),
    code: 'CAR',
    background: require('./car-home.jpg')
  },
  {
    name: 'Chicago Blackhawks',
    arena: 'United Center',
    logo: require('./logos/chi.png'),
    code: 'CHI',
    background: require('./chi-home.jpg')
  },
  {
    name: 'Colorado Avalanche',
    arena: 'Pepsi Center',
    logo: require('./logos/col.png'),
    code: 'COL',
    background: require('./col-home.jpg')
  },
  {
    name: 'Columbus Blue Jackets',
    arena: 'Nationwide Arena',
    logo: require('./logos/cbj.png'),
    code: 'CBJ',
    background: require('./cbj-home.jpg')
  },
  {
    name: 'Dallas Stars',
    arena: 'American Airlines Center',
    logo: require('./logos/dal.png'),
    code: 'DAL',
    background: require('./dal-home.jpg')
  },
  {
    name: 'Detroit Red Wings',
    arena: 'Little Caesars Arena',
    logo: require('./logos/det.png'),
    code: 'DET',
    background: require('./det-home.jpg')
  },
  {
    name: 'Edmonton Oilers',
    arena: 'Rogers Place',
    logo: require('./logos/edm.png'),
    code: 'EDM',
    background: require('./edm-home.jpg')
  },
  {
    name: 'Florida Panthers',
    arena: 'BB&T Center',
    logo: require('./logos/fla.png'),
    code: 'FLA',
    background: require('./fla-home.jpg')
  },
  {
    name: 'Los Angeles Kings',
    arena: 'Staples Center',
    logo: require('./logos/lak.png'),
    code: 'L.A',
    background: require('./lak-home.jpg')
  },
  {
    name: 'Minnesota Wild',
    arena: 'Xcel Energy Center',
    logo: require('./logos/min.png'),
    code: 'MIN',
    background: require('./min-home.jpg')
  },
  {
    name: 'Montreal Canadiens',
    arena: 'Bell Centre',
    logo: require('./logos/mtl.png'),
    code: 'MTL',
    background: require('./mtl-home.jpg')
  },
  {
    name: 'Nashville Predators',
    arena: 'Bridgestone Arena',
    logo: require('./logos/nsh.png'),
    code: 'NSH',
    background: require('./nsh-home.jpg')
  },
  {
    name: 'New Jersey Devils',
    arena: 'Prudential Center',
    logo: require('./logos/njd.png'),
    code: 'N.J',
    background: require('./njd-home.jpg')
  },
  {
    name: 'New York Islanders',
    arena: 'Barclays Center',
    logo: require('./logos/nyi.png'),
    code: 'NYI',
    background: require('./nyi-home.jpg')
  },
  {
    name: 'New York Rangers',
    arena: 'Madison Square Garden',
    logo: require('./logos/nyr.png'),
    code: 'NYR',
    background: require('./nyr-home.jpg')
  },
  {
    name: 'Ottawa Senators',
    arena: 'Canadian Tire Centre',
    logo: require('./logos/ott.png'),
    code: 'OTT',
    background: require('./ott-home.jpg')
  },
  {
    name: 'Philadelphia Flyers',
    arena: 'Wells Fargo Center',
    logo: require('./logos/phi.png'),
    code: 'PHI',
    background: require('./phi-home.jpg')
  },
  {
    name: 'Pittsburgh Penguins',
    arena: 'PPG Paints Arena',
    logo: require('./logos/pit.png'),
    code: 'PIT',
    background: require('./pit-home.jpg')
  },
  {
    name: 'San Jose Sharks',
    arena: 'SAP Center',
    logo: require('./logos/sjs.png'),
    code: 'S.J',
    background: require('./sjs-home.jpg')
  },
  {
    name: 'Seattle Kraken',
    arena: 'Climate Pledge Arena',
    logo: require('./logos/sea.png'),
    code: 'SEA',
    background: require('./sea-home.jpg')
  },
  {
    name: 'St. Louis Blues',
    arena: 'Scottrade Center',
    logo: require('./logos/stl.png'),
    code: 'STL',
    background: require('./stl-home.jpg')
  },
  {
    name: 'Tampa Bay Lightning',
    arena: 'Amalie Arena',
    logo: require('./logos/tbl.png'),
    code: 'TBL',
    background: require('./tbl-home.jpg')
  },
  {
    name: 'Toronto Maple Leafs',
    arena: 'Air Canada Centre',
    logo: require('./logos/tor.png'),
    code: 'TOR',
    background: require('./tor-home.jpg')
  },
  {
    name: 'Vancouver Canucks',
    arena: 'Rogers Arena',
    logo: require('./logos/van.png'),
    code: 'VAN',
    background: require('./van-home.jpg')
  },
  {
    name: 'Vegas Golden Knights',
    arena: 'T-Mobile Arena',
    logo: require('./logos/vgk.png'),
    code: 'VGK',
    background: require('./vgk-home.jpg')
  },
  {
    name: 'Washington Capitals',
    arena: 'Capital One Arena',
    logo: require('./logos/wsh.png'),
    code: 'WSH',
    background: require('./wsh-home.jpg')
  },
  {
    name: 'Winnipeg Jets',
    arena: 'Bell MTS Place',
    logo: require('./logos/wpg.png'),
    code: 'WPG',
    background: require('./wpg-home.jpg')
  }
];
