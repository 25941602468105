<template>
  <div>
    <div v-for="(date, i) in dates" :key="i">
      <h2>{{ date.date }}</h2>
      <div class="flex">
        <game-item v-for="(game, i) in date.games" :key="i" :game="game" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import teams from '../assets/teams';

import GameItem from './GameItem.vue';

export default {
  name: 'GameList',
  data: () => ({
    dates: [],
    games: [],
    teams,
    loading: true
  }),
  components: {
    GameItem
  },
  mounted() {
    this.getGameList('recap');
  },
  methods: {
    async getGameList(query) {
      const { data } = await axios.get(
        'https://www.reddit.com/r/icydata/search.json?q=' +
          query +
          '&sort=new&restrict_sr=on&limit=24'
      );

      this.mapGames(data.data.children);
      this.mapGamesByDates(this.games);
      console.log(this.dates);
    },
    mapGames(rawGames) {
      rawGames.forEach((game) => {
        this.games.push(this.mapSingleGame(game));
      });

      this.loading = false;
    },
    mapSingleGame(rawGame) {
      var splicedTitle = rawGame.data.title.split(' ');
      var date = new Date(rawGame.data.created_utc * 1000);
      return {
        awayTeam: this.teams.find((x) => x.code === splicedTitle[1]),
        homeTeam: this.teams.find((y) => y.code === splicedTitle[3]),
        url: rawGame.data.url,
        date: moment(date).subtract(12, 'hour').format('MMMM Do YYYY')
      };
    },
    mapGamesByDates(games) {
      games.forEach((game) => {
        this.checkAndAddDate(game);
        let index = this.dates.findIndex((x) => x.date == game.date);
        this.dates[index].games.push(game);
      });
    },
    checkAndAddDate(game) {
      if (!this.dates.some((x) => x.date === game.date)) {
        this.dates.push({
          date: game.date,
          games: []
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
